import React from 'react'
import { graphql } from 'gatsby'

export default ({data}) =>  (
  <>
    <h2>{data.site.siteMetadata.title} files</h2>
    <table style={{width: '100%'}}>
      <thead>
        <tr style={{fontWeight: 700}}>
          <td>relativePath</td>
          <td>prettySize</td>
          <td>extension</td>
          <td style={{textAlign: 'right'}}>birthTime</td>
        </tr>
      </thead>
      <tbody>
        {data.allFile.edges.map(({ node }, index) => (
          <tr key={index}>
            <td>{node.relativePath}</td>
            <td>{node.prettySize}</td>
            <td>{node.extension}</td>
            <td style={{textAlign: 'right'}}>{node.birthTime}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
)

export const query = graphql`
  query {
    allFile {
      edges {
        node {
          birthTime(fromNow: true) prettySize
          relativePath
          extension
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`